import React from "react";

const Navbar = ({ toggleTheme }) => {
  return (
    <div className="mynav">
      <h2>Git-View</h2>
    </div>
  );
};

export default Navbar;
