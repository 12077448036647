const mockUser = {
  login: "thilak009",
  id: 56732160,
  node_id: "MDQ6VXNlcjU2NzMyMTYw",
  avatar_url: "https://avatars3.githubusercontent.com/u/56732160?v=4",
  gravatar_id: "",
  url: "https://api.github.com/users/thilak009",
  html_url: "https://github.com/thilak009",
  followers_url: "https://api.github.com/users/thilak009/followers",
  following_url: "https://api.github.com/users/thilak009/following{/other_user}",
  gists_url: "https://api.github.com/users/thilak009/gists{/gist_id}",
  starred_url: "https://api.github.com/users/thilak009/starred{/owner}{/repo}",
  subscriptions_url: "https://api.github.com/users/thilak009/subscriptions",
  organizations_url: "https://api.github.com/users/thilak009/orgs",
  repos_url: "https://api.github.com/users/thilak009/repos",
  events_url: "https://api.github.com/users/thilak009/events{/privacy}",
  received_events_url: "https://api.github.com/users/thilak009/received_events",
  type: "User",
  site_admin: false,
  name: "thilak reddy",
  company: null,
  blog: "",
  location: null,
  email: null,
  hireable: null,
  bio: null,
  twitter_username: "thilak_reddy_ch",
  public_repos: 3,
  public_gists: 0,
  followers: 0,
  following: 0,
  created_at: "2019-10-18T15:00:43Z",
  updated_at: "2020-12-28T09:38:38Z"
  }

  export default mockUser;